import React from "react";
import HeaderShapes from "../HeaderShapes";
import {UiShowcase} from "../HeroImage";
import acadMobile from "../../images/hero-images/acad_mobile@2x.png";
import acadDesktop from "../../images/hero-images/acad@2x.png";
import {useIsMobile} from "./useIsMobile";

export function HeroImage() {
  const isMobile = useIsMobile()

  return isMobile ? (
    <div className="mobileChurchill">
      <img
        className="mobileChurchill-image"
        src={require('../../images/hero-images/acad_mobile@2x.png')}
        alt="Shows the UI of bizy, with the card: 'What were the key elements of Churchill's strategy in 1940?'"
      />
    </div>
  ) : (
    <div className="container-md">
      <div className="grid">
        <div className="shapes">
          <HeaderShapes activePanel="academic"/>
        </div>
        <div className="ui-elements">
          <div className="hero-images-group">
            <UiShowcase
              mobile={acadMobile}
              desktop={acadDesktop}
              alt="Shows the UI of bizy, with the card: 'What were the key elements of Churchill's strategy in 1940?'"
              activePanel="academic"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
