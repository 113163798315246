import React from 'react'

import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { Header } from '../components/AcademicTrial/Header';
import { SignUp } from '../components/AcademicTrial/SignUp';
import { HeroImage } from '../components/AcademicTrial/HeroImage';
import { HowItWorks } from '../components/AcademicTrial/HowItWorks';

function AcademicTrial() {
  return (
    <Layout pageName="academic-trial">
      <Nav showNavOnMobile={false}/>
      <div className="academic-trial-container">
        <Header />
        <SignUp />
        <HeroImage />
        <HowItWorks />
      </div>
      <Footer/>
    </Layout>
  )
}

export default AcademicTrial
